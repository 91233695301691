<template>
  <div class="relative">
    <SiteBanner :site-name="siteName" :site-address="siteAddress" />
    <WorkOrderDetails v-if="hasSelectedWorkOrder" class="mt-4" />
    <h2 class="my-2 pb-4 pt-1 text-center text-lg font-medium">
      {{ selectedService?.name }}
    </h2>
    <div class="mb-20 ml-auto mr-auto mt-4 w-11/12">
      <FormGenerator
        :schema="schema"
        :equations="equations"
        :maps="maps"
        :selected-frequency="selectedFrequency"
        @form-changed="formChangedEventHandler"
        @visibility-changed="updateVisibleComponentsEventHandler"
        @update-component-visibility="updateComponentVisibilityEventHandler"
        @persist-photo="storePhotoEventHandler"
        @remove-photo="removePhotoEventHandler"
        @trolley-collection-added="saveTrolleyCollection"
        @trolley-collection-removed="removeTrolleyCollection"
      />
      <WorkOrderForm
        v-if="hasSelectedWorkOrder"
        :show-errors="showWorkOrderErrors"
      />
      <PrimaryButton
        :key="submitButtonKey"
        action="Submit"
        class="mt-4"
        type="submit"
        :disabled="isLoading"
        @click.once="submitForm"
      />
    </div>
    <LoadingView :is-loading="isLoading" :message="loadingMessage" />
    <ScrollToTop />
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, onMounted, reactive, ref } from 'vue'
  import {
    deleteTrolleyCollection,
    getServiceForm,
    storeServiceRecord,
    storeTrolleyCollection,
  } from '@/services/api/serviceForm'
  import {
    onBeforeRouteLeave,
    RouteLocationNormalized,
    useRouter,
  } from 'vue-router'
  import dayjs from 'dayjs'
  import LoadingView from '@/views/LoadingView.vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import {
    Answer,
    canReplaceDefault,
    checkAnswers,
    errorMessage,
    FormGenerator,
    formSubmittableComponents,
    ImageAnswer,
    ImageType,
    removePhoto,
    SchemaType,
    scrollToFirstError,
    storePhoto,
    TrolleyCollection,
    TrolleyCollectionInput,
    updateComponentVisibility,
    updateVisibleComponents,
    validateFormSubmission,
  } from '@verifiedit/form-generator'
  import { getImages, removeImage, storeImage } from '@/services/api/image'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import ReportableException from '@/exceptions/ReportableException'
  import SupportableException from '@/exceptions/SupportableException'
  import WorkOrderForm from '@/components/WorkOrderForm.vue'
  import WorkOrderDetails from '@/components/WorkOrderDetails.vue'
  import {
    storeWorkOrderStatus,
    updateWorkOrder,
    WorkOrder,
  } from '@/services/api/workOrder'
  import { useFormAnswersStore } from '@/storage/formAnswers'
  import { storeToRefs } from 'pinia'
  import { useBypassStore } from '@/storage/bypass'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useFormsStore } from '@/storage/forms'
  import { useFrequenciesStore } from '@/storage/frequencies'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useServicesStore } from '@/storage/services'
  import { useSiteStore } from '@/storage/site'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { useUserStore } from '@/storage/user'
  import ScrollToTop from '@/components/ScrollToTop.vue'

  type FormDataAnswer = {
    question?: string
    images: string | (string | number)[]
    groupedBy?: string
    value:
      | string
      | number
      | boolean
      | (string | number | TrolleyCollection | ImageAnswer | ImageType)[]
  }

  export default defineComponent({
    name: 'ServiceForm',

    components: {
      FormGenerator,
      LoadingView,
      PrimaryButton,
      ScrollToTop,
      SiteBanner,
      WorkOrderDetails,
      WorkOrderForm,
    },

    async setup() {
      const {
        address: siteAddress,
        name: siteName,
        id: siteId,
        isSiteStoreDefault,
      } = storeToRefs(useSiteStore())
      const { setCompleted: setServiceCompleted, setServiceSiblings } =
        useServicesStore()
      const { selectedService, servicesAtSite, isSelectedServiceDefault } =
        storeToRefs(useServicesStore())
      const { id: customerId, isCustomerStoreDefault } =
        storeToRefs(useCustomerStore())
      const { setCompleted } = useFrequenciesStore()
      const { isSelectedFrequencyDefault } = storeToRefs(useFrequenciesStore())
      const { selectedFrequency } = storeToRefs(useFrequenciesStore())

      const {
        clearAnswers,
        setFrequencies,
        getFormAnswers,
        addFormAnswer,
        setJobStart,
        setServiceId,
        setSubServiceId,
        resetFormAnswers,
      } = useFormAnswersStore()
      const { jobStart, serviceId, subServiceId, frequencies } = storeToRefs(
        useFormAnswersStore(),
      )
      const { position } = storeToRefs(useUserPositionStore())
      const {
        selectedContractorCompanyId,
        id: userId,
        isSelectedContractorCompanyIdDefault,
        isUserStoreDefault,
      } = storeToRefs(useUserStore())
      const { scanBypassed, checkInDate } = storeToRefs(useBypassStore())
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const router = useRouter()
      const schema = ref<(SchemaType & errorMessage)[]>([])
      const answers: Map<string, Answer> = reactive(new Map())
      const deviceInfo = navigator.userAgent
      const visibleComponents = ref<string[]>([])
      //TODO replace the static visitLogId once authentication token is done
      const visitLogId = 0
      const isLoading = ref(true)
      const loadingMessage = ref('Saving service...')
      const submitButtonKey = ref(0)
      const { setServiceRecordId, resetForms } = useFormsStore()
      const { serviceRecordId } = storeToRefs(useFormsStore())
      const answersInStore = getFormAnswers(
        selectedService.value?.id ?? null,
        selectedFrequency.value,
        selectedService.value?.subServiceId ?? null,
      )
      const equations = ref({})
      const maps = ref({})
      const formId = ref<number | undefined>(undefined)
      const formName = ref<string | undefined>(undefined)

      const {
        populateWorkOrderData,
        setWorkOrderCompleteStatus,
        setWorkOrderResolutionDescription,
        resetWorkOrder,
      } = useWorkOrderStore()
      const {
        canSubmitWorkOrder,
        hasSelectedWorkOrder,
        selectedWorkOrder,
        workOrderCompleteStatus,
        workOrderResolutionDescription,
      } = storeToRefs(useWorkOrderStore())

      setJobStart()

      onBeforeRouteLeave((to: RouteLocationNormalized) => {
        if (to.name === 'serviceCheckout' || to.name === 'serviceSelection') {
          resetFormAnswers()
        }
      })

      onMounted(() => {
        setWorkOrderCompleteStatus(null)
        setWorkOrderResolutionDescription(null)
      })

      try {
        await fetchForm()
        schema.value.forEach((component) => {
          if (formSubmittableComponents.has(component.component)) {
            answers.set(component.identifier, component.default ?? '')
          }
        })
        isLoading.value = false
      } catch (e) {
        isLoading.value = false
        if (e instanceof SyntaxError) {
          throw new SupportableException('Service form error.', { error: e }, [
            'SchemaError',
          ])
        } else {
          throw e
        }
      }

      async function fetchForm() {
        if (
          isCustomerStoreDefault.value ||
          isSelectedServiceDefault.value ||
          isSelectedFrequencyDefault.value
        ) {
          throw new InvalidStoreException(
            {
              customerId: customerId.value,
              fingerprint: fingerprint.value,
              selectedFrequency: selectedFrequency.value,
              selectedServiceId: selectedService.value?.id,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'FetchServiceForm'],
          )
        }

        const response = await getServiceForm(
          customerId.value,
          selectedService.value?.id,
          selectedFrequency.value,
        )

        schema.value = response.schema as (SchemaType & errorMessage)[]
        equations.value = response.equations
        maps.value = response.maps
        formId.value = response.formId
        formName.value = response.formName

        populateWorkOrderData(schema.value as (SchemaType & errorMessage)[])

        schema.value.forEach((component) => {
          component.errorMessage = ''
          populateSavedDataOnForm(component as SchemaType & errorMessage)
        })
      }

      async function populateSavedDataOnForm(
        component: SchemaType & errorMessage,
      ) {
        if (answersInStore === null) {
          return
        }
        const answer = answersInStore.get(component.identifier)
        if (answer !== undefined && canReplaceDefault(component)) {
          if (component.component === 'PhotoInput') {
            component.images = await getImages(answer as number[])
          } else {
            component.default = answer
          }
        }
      }

      function saveFormDataInLocalStorage(change: {
        componentName: string
        value: string | (ImageAnswer | string | TrolleyCollection)[]
      }) {
        const isSameService =
          serviceId.value === selectedService.value?.id &&
          subServiceId.value === selectedService.value?.subServiceId
        const onSameFrequencies = frequencies.value.every((frequency) =>
          selectedFrequency.value.includes(frequency),
        )

        if (!isSameService || !onSameFrequencies) {
          clearAnswers()
        }

        setServiceId(selectedService.value?.id ?? null)
        setSubServiceId(selectedService.value?.subServiceId ?? null)
        setFrequencies(selectedFrequency.value)
        addFormAnswer(change.value, change.componentName)
      }

      function formChangedEventHandler(
        changedComponentName: string,
        userInput: string | (string | ImageAnswer | TrolleyCollection)[],
      ) {
        checkAnswers(
          changedComponentName,
          userInput,
          schema.value as (SchemaType & errorMessage)[],
          answers,
        )
        saveFormDataInLocalStorage({
          componentName: changedComponentName,
          value: userInput,
        })
      }

      function generateDateTime() {
        const currentDateTime = dayjs().format('DD-MM-YYYY h:mm:ssA')
        const systemRemark =
          scanBypassed.value && checkInDate.value !== ''
            ? checkInDate.value
            : dayjs().format('DD-MM-YYYY')
        return [currentDateTime, systemRemark]
      }

      function getValueForKey(componentName: string, key: string) {
        let value = ''

        schema.value.forEach((component) => {
          if (component.identifier === componentName && key in component) {
            value = component[key] as string
          }
        })

        return value
      }

      function transformAnswers(answers: Map<string, Answer>) {
        const formData: { [key: string]: FormDataAnswer } = {}
        for (const [componentName, value] of answers) {
          const question = getValueForKey(componentName, 'question')
          const groupedBy = getValueForKey(componentName, 'groupedBy')
          const template: FormDataAnswer = {
            groupedBy: '',
            images: '',
            question: '',
            value: '',
          }
          if (question !== '') {
            template['question'] = question
          }

          if (groupedBy !== '') {
            template['groupedBy'] = groupedBy
          }

          template['value'] = value

          formData[componentName] = template
        }

        for (const key in formData) {
          if (key.includes('Photo')) {
            const formDataToUpdate =
              formData[key.substring(0, key.indexOf('Photo'))]
            if (formDataToUpdate) {
              formDataToUpdate['images'] = formData[key]['value'] as (
                | string
                | number
              )[]
              delete formData[key]
            }
          }
        }

        return JSON.stringify(formData)
      }

      function updateVisibleComponentsEventHandler(
        components: Map<string, string>,
      ) {
        visibleComponents.value = updateVisibleComponents(components, answers)
      }

      function setServiceCompletedOnFrequency(
        serviceId: number | null,
        subServiceId: number | null,
        frequencyIds: number[],
      ) {
        setServiceCompleted(serviceId, subServiceId)
        frequencyIds.forEach((frequencyId) => {
          setCompleted(serviceId, subServiceId, frequencyId)
        })
      }

      async function handleWorkOrder(): Promise<WorkOrder> {
        return await updateWorkOrder(
          customerId.value ?? 0,
          (selectedWorkOrder.value as WorkOrder).id,
          workOrderResolutionDescription.value ?? '',
          workOrderCompleteStatus.value,
          serviceRecordId.value,
        )
      }

      async function handleWorkOrderStatus(): Promise<void> {
        if (selectedWorkOrder.value === null) {
          return
        }

        await storeWorkOrderStatus(
          customerId.value ?? 0,
          selectedWorkOrder.value.id ?? 0,
          serviceRecordId.value,
          workOrderCompleteStatus.value,
        )
      }

      const showWorkOrderErrors = ref<boolean>(false)
      async function submitForm() {
        submitButtonKey.value++
        showWorkOrderErrors.value = true
        const canSubmit =
          validateFormSubmission(
            schema.value as (SchemaType & errorMessage)[],
            answers,
            visibleComponents.value,
          ) &&
          (canSubmitWorkOrder.value || !hasSelectedWorkOrder.value)

        if (!canSubmit) {
          // eslint-disable-next-line vue/next-tick-style
          await nextTick()
          scrollToFirstError()
          return
        }
        const [jobEndTime, systemRemark] = generateDateTime()
        const formData = transformAnswers(answers)
        resetForms()

        if (
          isCustomerStoreDefault.value ||
          isSiteStoreDefault.value ||
          isSelectedServiceDefault.value ||
          isSelectedFrequencyDefault.value ||
          isSelectedContractorCompanyIdDefault.value
        ) {
          throw new InvalidStoreException(
            {
              customer: customerId.value,
              fingerprint: fingerprint.value,
              selectedContractorCompany: selectedContractorCompanyId.value,
              selectedFrequency: selectedFrequency.value,
              selectedServiceId: selectedService.value?.id,
              selectedSubServiceId: selectedService.value?.subServiceId,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreServiceFormSubmission'],
          )
        }

        isLoading.value = true

        loadingMessage.value = 'Saving service...'
        const response = await storeServiceRecord(
          deviceInfo,
          jobStart.value ?? '',
          jobEndTime,
          customerId.value,
          siteId.value,
          selectedService.value?.id,
          selectedService.value?.subServiceId,
          selectedFrequency.value,
          systemRemark,
          visitLogId,
          selectedContractorCompanyId.value,
          position.value.latitude,
          position.value.longitude,
          position.value.altitude,
          position.value.accuracy,
          formData,
          formId.value,
          formName.value,
          selectedWorkOrder.value?.id ?? null,
        )
        setServiceCompletedOnFrequency(
          selectedService.value?.id ?? null,
          selectedService.value?.subServiceId ?? null,
          selectedFrequency.value,
        )

        if (response && response.id !== undefined) {
          setServiceRecordId(response.id)
        }

        loadingMessage.value = 'Saving work order...'

        if (workOrderCompleteStatus.value === 'COMPLETED') {
          await handleWorkOrder()
        } else {
          await handleWorkOrderStatus()
        }

        isLoading.value = false

        if (response?.serviceSiblings && !hasSelectedWorkOrder.value) {
          const availableSiblings = response.serviceSiblings.filter(
            (service) => {
              return servicesAtSite.value.find(
                (serviceAtSite) => serviceAtSite.id === service.id,
              )
            },
          )
          setServiceSiblings(availableSiblings)
          await router.replace({
            name: 'serviceSiblingSelection',
          })

          return
        }

        resetWorkOrder()
        await router.replace({
          name: 'serviceCheckout',
        })
      }

      async function saveTrolleyCollection(
        trolleyCollection: TrolleyCollection,
        component: InstanceType<typeof TrolleyCollectionInput>,
      ) {
        loadingMessage.value = 'Saving collection...'
        isLoading.value = true
        const status = await storeTrolleyCollection(trolleyCollection)

        if (status === 201) {
          component.addCollection(trolleyCollection)
        } else {
          throw new ReportableException(
            'There was a problem saving collection',
            { status: status },
          )
        }
        isLoading.value = false
      }

      async function removeTrolleyCollection(
        trolleyCollection: TrolleyCollection,
        component: InstanceType<typeof TrolleyCollectionInput>,
      ) {
        loadingMessage.value = 'Removing collection...'
        isLoading.value = true
        const status = await deleteTrolleyCollection(trolleyCollection)

        if (status === 200) {
          component.deleteCollection()
        } else {
          throw new ReportableException(
            'There was a problem deleting collection',
            { status: status },
          )
        }
        isLoading.value = false
      }

      async function storePhotoEventHandler(
        imageData: string,
        imageTypeId: number,
        sequenceOfImage: number,
        identifier: string,
      ) {
        if (
          isCustomerStoreDefault.value ||
          isSiteStoreDefault.value ||
          isUserStoreDefault.value
        ) {
          throw new InvalidStoreException(
            {
              customerId: customerId.value,
              fingerprint: fingerprint.value,
              siteId: siteId.value,
              userId: userId.value,
            },
            ['InvalidStore', 'StoreFormPhotoUpload'],
          )
        }

        loadingMessage.value = 'Saving image...'
        isLoading.value = true
        await storePhoto(
          imageData,
          imageTypeId,
          sequenceOfImage,
          identifier,
          schema.value as (SchemaType & errorMessage)[],
          () =>
            storeImage(
              customerId.value,
              siteId.value,
              userId.value,
              imageData,
              imageTypeId,
              sequenceOfImage,
              serviceId.value as number,
              subServiceId.value as number,
            ),
        )
        isLoading.value = false
      }

      async function removePhotoEventHandler(
        imageId: string,
        identifier: string,
      ) {
        loadingMessage.value = 'Removing image...'
        isLoading.value = true
        await removePhoto(
          imageId,
          identifier,
          schema.value as (SchemaType & errorMessage)[],
          answers,
          removeImage,
        )
        isLoading.value = false
      }

      function updateComponentVisibilityEventHandler(
        identifier: string,
        result: { items: string[]; value: boolean },
        key: string,
      ) {
        updateComponentVisibility(
          identifier,
          result,
          key,
          schema.value as (SchemaType & errorMessage)[],
        )
      }

      return {
        answers,
        equations,
        formChangedEventHandler,
        hasSelectedWorkOrder,
        isLoading,
        loadingMessage,
        maps,
        removePhotoEventHandler,
        removeTrolleyCollection,
        router,
        saveTrolleyCollection,
        schema,
        selectedFrequency,
        selectedService,
        showWorkOrderErrors,
        siteAddress,
        siteName,
        storePhotoEventHandler,
        submitButtonKey,
        submitForm,
        updateComponentVisibilityEventHandler,
        updateVisibleComponentsEventHandler,
      }
    },
  })
</script>
