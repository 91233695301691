<template>
  <section class="mx-10 mb-4">
    <h3 class="mb-4 border-t border-gray-500 pt-4 text-center text-3xl">
      Site Representative Authorisation
    </h3>
    <p class="mb-2">I have advised {{ fullName }} of the following:</p>
    <ul class="list-disc">
      <li v-for="(item, idx) in supervisorChecklist" :key="idx" class="ml-4">
        {{ item }}
      </li>
    </ul>
    <TextInput
      identifier="siteRepName"
      placeholder="Site Representative Name"
      :error-message="supervisorNameError"
      :show="true"
      @form-changed="updateName"
    />
    <SignatureInput
      identifier="siteRepSignature"
      content="Site Representative Signature"
      :error-message="supervisorSignatureError"
      :show="true"
      @form-changed="updateSignature"
    />
  </section>
</template>

<script setup lang="ts">
  import { SignatureInput, TextInput } from '@verifiedit/form-generator'
  import { storeToRefs } from 'pinia'
  import { useCustomerStore } from '@/storage/customer'
  import { useUserStore } from '@/storage/user'

  const { supervisorChecklist } = storeToRefs(useCustomerStore())
  const { fullName } = storeToRefs(useUserStore())

  defineProps({
    // eslint-disable-next-line vue/no-unused-properties
    supervisorName: {
      default: '',
      type: String,
    },
    supervisorNameError: {
      default: '',
      type: String,
    },
    // eslint-disable-next-line vue/no-unused-properties
    supervisorSignature: {
      default: '',
      type: String,
    },
    supervisorSignatureError: {
      default: '',
      type: String,
    },
  })
  const emit = defineEmits([
    'update:supervisorName',
    'update:supervisorSignature',
    'update:supervisorNameError',
    'update:supervisorSignatureError',
  ])

  function updateName(_identifier: string, value: string) {
    emit('update:supervisorNameError', '')
    emit('update:supervisorName', value)
  }

  function updateSignature(_identifier: string, value: string) {
    emit('update:supervisorSignatureError', '')
    emit('update:supervisorSignature', value)
  }
</script>
