<template>
  <li class="mb-2" @click="showChecks = !showChecks">
    <div class="flex items-center justify-between">
      <div class="flex">
        <p data-test="check-title" class="font-bold">
          {{ siteEntryCheck.title }}
        </p>
        <ChevronDownIcon
          v-if="siteEntryCheck.checks && siteEntryCheck.checks.length > 0"
          class="ml-2 w-4 stroke-2"
          :class="{ 'rotate-180': showChecks }"
        />
      </div>
      <ExclamationTriangleIcon
        v-if="!siteEntryCheck.passed && siteEntryCheck.canContinue"
        class="w-8 stroke-yellow-600"
      />
      <CheckCircleIcon
        v-else-if="siteEntryCheck.canContinue"
        class="w-8 stroke-green-600"
      />
      <XCircleIcon v-else class="w-8 stroke-red-600" />
    </div>
    <ul
      v-if="siteEntryCheck.checks && showChecks"
      class="mt-0.5"
      data-test="checks-list"
    >
      <li
        v-for="check in siteEntryCheck.checks"
        :key="check.name"
        class="mb-0.5"
      >
        <div class="flex items-center justify-between">
          <p class="text-sm">{{ check['displayName'] ?? check.name }}</p>
          <div class="flex w-8 items-center justify-center">
            <CheckCircleIcon
              v-if="check.result === 'Pass'"
              class="w-6 stroke-green-600"
            />
            <ExclamationTriangleIcon
              v-else-if="check.result === 'Fail'"
              class="w-6 stroke-yellow-600"
            />
            <XCircleIcon v-else class="w-6 stroke-red-600" />
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue'
  import {
    CheckCircleIcon,
    ChevronDownIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
  } from '@heroicons/vue/24/outline'
  import {
    CheckType,
    SiteEntryCheckType,
  } from '@/storage/siteEntryConfirmation'
  import { AccreditationCheckType } from '@/services/api/accreditation'

  export default defineComponent({
    name: 'SiteEntryCheck',
    components: {
      CheckCircleIcon,
      ChevronDownIcon,
      ExclamationTriangleIcon,
      XCircleIcon,
    },

    props: {
      siteEntryCheck: {
        type: Object as PropType<
          SiteEntryCheckType<CheckType | AccreditationCheckType>
        >,

        required: true,
      },
    },

    setup() {
      const showChecks = ref(false)

      return {
        showChecks,
      }
    },
  })
</script>
