<template>
  <section>
    <h3 class="my-2 font-bold">{{ accreditation?.name }}</h3>
    <div class="mb-6 flex items-center">
      <div class="flex-1 space-x-4">
        <input
          :id="'Yes' + accreditation.id"
          v-model="hasAccreditation"
          type="radio"
          :name="'hasAccreditation' + accreditation.id"
          :value="true"
          @click="hasAccreditation = true"
        />
        <label :for="'Yes' + accreditation.id">Yes</label>
      </div>

      <div class="flex-1 space-x-4">
        <input
          :id="'No' + accreditation.id"
          v-model="hasAccreditation"
          type="radio"
          :name="'hasAccreditation' + accreditation.id"
          :value="false"
          @click="hasAccreditation = false"
        />
        <label :for="'No' + accreditation.id">No</label>
      </div>
    </div>
    <div v-if="hasAccreditation" ref="form" class="mb-6 space-y-6">
      <section v-if="accreditation?.referenceIdRequired">
        <BaseInput
          v-model:inputValue="formData.referenceId"
          label="Accreditation Number"
          identifier="referenceId"
          :is-valid="errorMessage.referenceId === ''"
          :disabled="isLoading"
        >
          <span v-if="errorMessage.referenceId" class="error m-1 !my-0 block">
            {{ errorMessage.referenceId }}
          </span>
        </BaseInput>
      </section>
      <section v-if="accreditation?.descriptionRequired">
        <BaseInput
          v-model:inputValue="formData.description"
          label="Description"
          identifier="description"
          :is-valid="errorMessage.description === ''"
          :disabled="isLoading"
        >
          <span v-if="errorMessage.description" class="error m-1 !my-0 block">
            {{ errorMessage.description }}
          </span>
        </BaseInput>
      </section>
      <section
        v-if="accreditation?.validFromRequired"
        :id="'validFrom' + accreditation.id"
      >
        <VueDatePicker
          v-model="formData.validFrom"
          :enable-time-picker="false"
          placeholder="Valid From"
          format="dd-MM-yyyy"
          :max-date="new Date()"
          auto-apply
        />
        <span v-if="errorMessage.validFrom" class="error m-1 !my-0 block">
          {{ errorMessage.validFrom }}
        </span>
      </section>
      <section
        v-if="accreditation?.expiresAtRequired"
        :id="'expiresAt' + accreditation.id"
      >
        <VueDatePicker
          v-model="formData.expiresAt"
          :enable-time-picker="false"
          format="dd-MM-yyyy"
          placeholder="Expiry Date"
          :min-date="new Date()"
          :max-date="dayjs().add(10, 'year').toDate()"
          auto-apply
        />
        <span v-if="errorMessage.expiresAt" class="error m-1 !my-0 block">
          {{ errorMessage.expiresAt }}
        </span>
      </section>
    </div>
    <AlertBanner
      v-else-if="hasAccreditation === false"
      class="mb-6"
      component-style="warning"
      content="This site requires you to have this accreditation."
    />
    <span
      :data-test="
        accreditation?.name.toLowerCase().replace(' ', '-') +
        '-acknowledgment-statement'
      "
    >
      By submitting this form, you confirm that the information provided is
      correct.
    </span>
  </section>
</template>

<script setup lang="ts">
  import { PropType, reactive, ref, toRef, watch } from 'vue'
  import BaseInput from '@/components/BaseInput.vue'
  import { AccreditationType } from '@/services/api/accreditation'
  import ReportableException from '@/exceptions/ReportableException'
  import AlertBanner from '@/components/AlertBanner.vue'
  import dayjs from 'dayjs'
  import customParseFormat from 'dayjs/plugin/customParseFormat'
  import { useUserStore } from '@/storage/user'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'

  dayjs.extend(customParseFormat)

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
    shouldValidate: Boolean,
  })

  const emit = defineEmits([
    'canSave',
    'hasAccreditation',
    'doesntHaveAccreditation',
    'validated',
  ])

  const formData = reactive({
    description: '',
    expiresAt: '',
    referenceId: '',
    validFrom: '',
  })

  const errorMessage = reactive({
    description: '',
    expiresAt: '',
    referenceId: '',
    validFrom: '',
  })

  const form = ref<HTMLDivElement | null>(null)
  const mandatoryFields = ref<string[]>([])
  const accreditation = toRef(props, 'accreditation')
  const isLoading = ref(false)
  const hasAccreditation = ref<boolean | null>(null)

  watch(
    () => formData.referenceId,
    (change) => {
      manageErrorMessage(change, 'referenceId')
    },
  )

  watch(
    () => formData.description,
    (change) => {
      manageErrorMessage(change, 'description')
    },
  )

  watch(
    () => formData.validFrom,
    (change) => {
      manageErrorMessage(change, 'validFrom')
    },
  )

  watch(
    () => formData.expiresAt,
    (change) => {
      manageErrorMessage(change, 'expiresAt')
    },
  )

  watch(
    () => props.shouldValidate,
    () => {
      if (props.shouldValidate) {
        validate()
      }
    },
  )

  watch(hasAccreditation, (hasAccreditation) => {
    if (hasAccreditation) {
      emit('hasAccreditation', accreditation.value?.id)
    } else {
      emit('doesntHaveAccreditation', accreditation.value?.id)
    }
  })

  function manageErrorMessage(data: string | null, fieldName: string) {
    if (data !== '' && data !== null) {
      errorMessage[fieldName] = ''
      return
    }

    errorMessage[fieldName] = 'Field cannot be empty'
  }

  function getMandatoryFields() {
    Object.entries(props.accreditation)
      .map(([fieldName, visibility]) => [
        fieldName.replace('Required', ''),
        visibility,
      ])
      .filter(
        ([fieldName, visibility]) =>
          visibility &&
          Object.prototype.hasOwnProperty.call(formData, fieldName as string),
      )
      .map(([fieldName]) => mandatoryFields.value.push(fieldName as string))

    mandatoryFields.value = [...new Set(mandatoryFields.value)]
  }

  function validate(): boolean {
    if (hasAccreditation.value === false) {
      emit('validated')
      return false
    }

    getMandatoryFields()

    mandatoryFields.value.forEach((field) => {
      manageErrorMessage(formData[field], field)
    })

    const isValid = Object.values(errorMessage).every(
      (message) => message === '',
    )

    emit('validated')

    emit('canSave', {
      canSave: isValid,
      userAccreditation: isValid ? acquireData() : null,
    })

    return isValid
  }

  function acquireData() {
    if (accreditation.value?.id === undefined) {
      throw new ReportableException('Missing accreditation data', {
        accreditation: accreditation.value,
      })
    }

    return createUserAccreditationRequestData()
  }

  function createUserAccreditationRequestData() {
    const data = {
      accreditationId: accreditation.value.id,
      accreditationName: accreditation.value.name,
      description: formData.description,
      expiresAt: formData.expiresAt
        ? dayjs(formData.expiresAt).endOf('day').format('DD-MM-YYYY HH:mm:ss')
        : '',
      referenceId: formData.referenceId,
      userId: useUserStore().id,
      validFrom: formData.validFrom,
    }

    if (accreditation.value?.userAccreditations[0] !== undefined) {
      data['userAccreditationId'] =
        accreditation.value?.userAccreditations[0].id
    }

    return data
  }
</script>
