import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { eventMap } from '@/utilities/session'

export const useSessionStore = defineStore(
  'session',
  () => {
    /** state */
    const events = ref<string[]>([])
    const siteSessionId = ref<string | null>(null)
    const sessionTtl = ref<number>(600)
    const activeLoadedAt = ref(0)

    /** getters */
    const currentEvent: ComputedRef<string> = computed(
      () => events.value[events.value.length - 1] || '',
    )

    /** actions */
    function addSessionEvent(value: string): void {
      if (eventMap().get(value) !== undefined) {
        events.value.push(value)
      }
    }

    function setSiteSessionId(value: string): void {
      siteSessionId.value = value
    }

    function setSessionTtl(value: number): void {
      sessionTtl.value = value
    }

    function setActiveLoadedAt(value: number): void {
      if (value > activeLoadedAt.value) {
        activeLoadedAt.value = value
      }
    }

    function resetSession() {
      events.value = []
      siteSessionId.value = null
      sessionTtl.value = 60
    }

    return {
      activeLoadedAt,
      addSessionEvent,

      currentEvent,
      events,
      resetSession,
      sessionTtl,
      setActiveLoadedAt,
      setSessionTtl,
      setSiteSessionId,
      siteSessionId,
    }
  },
  {
    persist: true,
  },
)
