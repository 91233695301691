<template>
  <section>
    <h3 class="mb-6 font-bold">Work Eligibility</h3>
    <p class="block">What work rights do you have in Australia?</p>
    <div class="mb-4 mt-2 flex flex-col space-y-2">
      <div class="flex-1 space-x-4">
        <input
          id="visa-check-is-citizen"
          v-model="workingRights"
          name="working-rights"
          type="radio"
          :value="workingRightsOptions.isCitizen"
        />
        <label for="visa-check-is-citizen">
          {{ workingRightsOptions.isCitizen }}
        </label>
      </div>
      <div class="flex-1 space-x-4">
        <input
          id="visa-check-has-working-rights"
          v-model="workingRights"
          name="working-rights"
          type="radio"
          :value="workingRightsOptions.hasWorkingRights"
        />
        <label for="visa-check-has-working-rights">
          {{ workingRightsOptions.hasWorkingRights }}
        </label>
      </div>
      <div class="flex-1 space-x-4">
        <input
          id="visa-check-has-no-working-rights"
          v-model="workingRights"
          name="working-rights"
          type="radio"
          :value="workingRightsOptions.hasNoWorkingRights"
        />
        <label for="visa-check-has-no-working-rights">
          {{ workingRightsOptions.hasNoWorkingRights }}
        </label>
      </div>
    </div>

    <div v-if="isCitizen === false">
      <div v-if="hasWorkingRights" class="mb-4 mt-6 flex-1 space-y-4">
        <p class="block">Provide passport details:</p>
        <BaseInput
          v-model:inputValue="passportNo"
          label="Passport/Document No."
          identifier="visa-check-document-no"
          :is-valid="errorMessage.passportNo === ''"
          :disabled="isLoading"
        >
          <span v-if="errorMessage.passportNo" class="error m-1 !my-0 block">
            {{ errorMessage.passportNo }}
          </span>
        </BaseInput>

        <ComboboxInput
          :options="countryList"
          placeholder="Country"
          identifier="visaCountry"
          @option-changed="selectCountry"
        >
          <span v-if="errorMessage.country" class="error m-1 !my-0 block">
            {{ errorMessage.country }}
          </span>
        </ComboboxInput>

        <div :id="accreditation.id + 'dateOfBirth'">
          <VueDatePicker
            v-model="dateOfBirth"
            :enable-time-picker="false"
            placeholder="Date of birth"
            format="dd-MM-yyyy"
            :max-date="new Date()"
            auto-apply
          />
          <span v-if="errorMessage.dateOfBirth" class="error m-1 !my-0 block">
            {{ errorMessage.dateOfBirth }}
          </span>
        </div>

        <div class="flex items-baseline space-x-2">
          <input
            id="visa-check-consent"
            v-model="canPerformVisaCheck"
            type="checkbox"
            class="outline-0"
            :value="true"
            @click="canPerformVisaCheck = true"
          />
          <label for="visa-check-consent" class="self-center text-xs">
            I acknowledge that Verified will perform a check on my Visa
            conditions via VEVO to obtain the status and conditions of my visa
            on behalf of our client Hertz to ensure they meet their legal
            obligations. I understand that the entered data will not be stored
            in Verified. Verified will capture the result as pass or fail and
            store the date of expiry. I am aware that if I am not entitled to be
            in Australia, the Commonwealth may use the information obtained to
            locate me. I confirm my understanding and consent to the VEVO
            inquiry process.
          </label>
        </div>
        <span
          v-if="errorMessage.canPerformVisaCheck"
          class="error m-1 !my-0 block"
        >
          {{ errorMessage.canPerformVisaCheck }}
        </span>
      </div>
      <AlertBanner
        v-else-if="hasWorkingRights === false && workingRights"
        class="mb-6"
        component-style="warning"
        content="This site requires you to have rights to work."
      />
    </div>
  </section>
</template>

<script setup lang="ts">
  import countries from 'country-list-js'
  import { computed, PropType, reactive, ref, watch, watchEffect } from 'vue'
  import BaseInput from '@/components/BaseInput.vue'
  import dayjs from 'dayjs'
  import { AccreditationType } from '@/services/api/accreditation'
  import { useUserStore } from '@/storage/user'
  import ReportableException from '@/exceptions/ReportableException'
  import AlertBanner from '@/components/AlertBanner.vue'
  import ComboboxInput from '@/components/ComboboxInput.vue'
  import VueDatePicker from '@vuepic/vue-datepicker'

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
    shouldValidate: Boolean,
  })

  const emit = defineEmits([
    'canSave',
    'hasAccreditation',
    'doesntHaveAccreditation',
    'validated',
  ])

  const countryList = countries
    .names()
    .sort()
    .map((countryName) => {
      return { id: countryName, name: countryName }
    })

  const country = ref<string | undefined>(undefined)
  const dateOfBirth = ref<string | undefined>(undefined)
  const passportNo = ref<string | undefined>(undefined)
  const workingRights = ref<string | undefined>(undefined)
  const workingRightsOptions = {
    hasNoWorkingRights: "I don't have working rights",
    hasWorkingRights: 'I am a Visa holder with a right to work',
    isCitizen: 'I am Australian Citizen/Permanent Resident',
  }

  const isCitizen = computed(() => {
    return workingRights.value === workingRightsOptions.isCitizen
  })
  const hasWorkingRights = computed(() => {
    return workingRights.value === workingRightsOptions.hasWorkingRights
  })

  const isLoading = ref(false)
  const canPerformVisaCheck = ref(false)

  const errorMessage = reactive({
    canPerformVisaCheck: '',
    country: '',
    dateOfBirth: '',
    passportNo: '',
  })

  watch(workingRights, () => {
    if (
      workingRights.value === workingRightsOptions.isCitizen ||
      workingRights.value === workingRightsOptions.hasWorkingRights
    ) {
      emit('hasAccreditation', props.accreditation?.id)
    } else if (
      workingRights.value === workingRightsOptions.hasNoWorkingRights
    ) {
      emit('doesntHaveAccreditation', props.accreditation?.id)
    }
  })

  watchEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  })

  watch(dateOfBirth, () => {
    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
  })
  watch(country, () =>
    manageErrorMessage(country.value, 'country', 'Invalid country name'),
  )
  watch(canPerformVisaCheck, () => {
    manageConsent()
  })
  watch(passportNo, () => manageErrorMessage(passportNo.value, 'passportNo'))

  function manageErrorMessage(
    data: string | undefined,
    fieldName: string,
    message: string = 'Field cannot be empty',
  ) {
    if (data && data !== '') {
      errorMessage[fieldName] = ''
      return
    }

    errorMessage[fieldName] = message
  }

  function manageConsent() {
    errorMessage.canPerformVisaCheck = canPerformVisaCheck.value
      ? ''
      : 'Must give consent'
  }

  function validate(): boolean {
    if (isCitizen.value) {
      emit('validated')
      emit('canSave', {
        canSave: true,
        userAccreditation: createUserAccreditationRequestData(),
      })

      return true
    }

    if (!hasWorkingRights.value) {
      emit('validated')
      return false
    }

    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
    manageErrorMessage(country.value, 'country', 'Invalid country name')
    manageErrorMessage(passportNo.value, 'passportNo')
    manageConsent()

    const isValid = Object.values(errorMessage).every(
      (message) => message === '',
    )

    emit('validated')
    emit('canSave', {
      canSave: isValid,
      userAccreditation: isValid ? createUserAccreditationRequestData() : null,
    })

    return isValid
  }

  function selectCountry(selectedCountry: { id: string; name: string } | null) {
    country.value = selectedCountry ? selectedCountry.name : undefined
  }

  function createUserAccreditationRequestData() {
    if (props.accreditation.id === undefined) {
      throw new ReportableException('Missing accreditation data', {
        accreditation: props.accreditation,
      })
    }

    const data = {
      accreditationId: props.accreditation.id,
      accreditationName: props.accreditation.name,
      isCitizen: isCitizen.value,
      userId: useUserStore().id,
    }

    if (!isCitizen.value && hasWorkingRights.value) {
      data['consent'] = canPerformVisaCheck.value
      data['country'] = countries.findByName(country.value)?.code.iso3
      data['passportNo'] = passportNo.value
      data['dateOfBirth'] = dayjs(dateOfBirth.value).format('DD-MM-YYYY')
    }

    return data
  }
</script>
