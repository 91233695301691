import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { AccreditationCheckType } from '@/services/api/accreditation'
import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'

export type CheckType = {
  name: string
  result: string
}

export type SiteEntryTitle = 'Work Order' | 'Induction' | 'Accreditation'

export type SiteEntryCheckType<T extends CheckType | AccreditationCheckType> = {
  title: SiteEntryTitle
  passed: boolean
  canContinue: boolean
  checks?: T[]
}

export const useSiteEntryConfirmationStore = defineStore(
  'siteEntryConfirmation',
  () => {
    /** state */
    const siteEntryChecks = ref<
      SiteEntryCheckType<CheckType | AccreditationCheckType>[]
    >([])
    const canCheckout = ref<boolean>(false)

    /** getters */
    const canContinue = computed(() =>
      siteEntryChecks.value.every((check) => check.canContinue),
    )

    const pass = computed(() =>
      siteEntryChecks.value.every((check) => check.passed),
    )

    const canViewSiteEntry: ComputedRef<boolean> = computed(
      // canCheckout is set through the route guard for siteEntry, meaning it will be available
      () => canCheckout.value && siteEntryChecks.value.length > 0,
    )

    const passedWorkOrderCheck: ComputedRef<boolean> = computed(() => {
      const workOrderCheck = getSiteEntryCheck('Work Order')
      return !!workOrderCheck?.passed
    })

    /** actions */
    function setCanCheckout(value: boolean) {
      canCheckout.value = value
    }

    function setSiteEntryChecks(value: SiteEntryCheckType<CheckType>[]) {
      siteEntryChecks.value = value
    }

    function removeSiteEntryCheck(check: SiteEntryCheckType<CheckType>): void {
      const checkToRemove = getSiteEntryCheck(check.title)

      if (checkToRemove) {
        siteEntryChecks.value.splice(
          siteEntryChecks.value.indexOf(checkToRemove),
          1,
        )
      }
    }

    function addSiteEntryCheck(check: SiteEntryCheckType<CheckType>): void {
      const checkInStore = getSiteEntryCheck(check.title)

      if (checkInStore) {
        updateSiteEntryCheck(checkInStore, check.passed)
      } else {
        siteEntryChecks.value.push(check)
      }
    }

    function updateSiteEntryCheck(
      check: SiteEntryCheckType<CheckType>,
      value: boolean,
    ): void {
      check.passed = value
    }

    function resetSiteEntryConfirmation() {
      siteEntryChecks.value = []
      canCheckout.value = false
    }

    return {
      addSiteEntryCheck,
      canCheckout,
      canContinue,
      canViewSiteEntry,
      pass,
      passedWorkOrderCheck,
      removeSiteEntryCheck,
      resetSiteEntryConfirmation,
      setCanCheckout,
      setSiteEntryChecks,
      siteEntryChecks,
    }
  },
  {
    persist: true,
  },
)
