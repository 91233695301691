<template>
  <div>
    <SiteBanner :site-name="siteNameStatic" :site-address="siteAddressStatic" />
    <div class="px-4">
      <h1 class="mt-4 text-center text-3xl">{{ headingText }}</h1>
      <ExclamationTriangleIcon
        v-if="!passStatic && canContinueStatic"
        class="pass-fail-large stroke-yellow-600"
      />
      <ArrowLeftEndOnRectangleIcon
        v-else-if="canContinueStatic"
        class="pass-fail-large stroke-green-600"
      />
      <XCircleIcon v-else class="pass-fail-large stroke-red-600" />
      <p id="user-type" class="mb-4 text-center text-lg font-bold">
        {{ userTypeNameStatic }}
      </p>
      <img
        v-if="employeePhotoStatic"
        class="mx-auto aspect-square w-1/3"
        :src="'data:image/png;base64,' + employeePhotoStatic"
        alt="Employee Photo"
      />
      <div class="mx-6 mt-4">
        <div class="information-container">
          <p>Name</p>
          <p>{{ userFullName }}</p>
        </div>
        <div class="information-container">
          <p>Company</p>
          <p>{{ contractorName }}</p>
        </div>
        <div class="information-container">
          <p>Date</p>
          <p>{{ entryDate }}</p>
        </div>
        <ul class="my-4">
          <SiteEntryCheck
            v-for="siteEntryCheck in siteEntryChecksStatic"
            :key="siteEntryCheck.title"
            :site-entry-check="siteEntryCheck"
          />
        </ul>
        <p class="mb-4 text-center text-sm">
          {{ reportToText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import {
    ArrowLeftEndOnRectangleIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
  } from '@heroicons/vue/24/outline'
  import SiteBanner from '@/components/SiteBanner.vue'
  import SiteEntryCheck from '@/components/SiteEntryCheck.vue'
  import dayjs from 'dayjs'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useSiteStore } from '@/storage/site'
  import { useSiteEntryConfirmationStore } from '@/storage/siteEntryConfirmation'
  import { useUserVisitorCompaniesStore } from '@/storage/userVisitorCompanies'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'SiteEntryInformation',
    components: {
      ArrowLeftEndOnRectangleIcon,
      ExclamationTriangleIcon,
      SiteBanner,
      SiteEntryCheck,
      XCircleIcon,
    },

    setup() {
      const { name: siteName, address: siteAddress } =
        storeToRefs(useSiteStore())
      const { pass, siteEntryChecks, canContinue } = storeToRefs(
        useSiteEntryConfirmationStore(),
      )
      const {
        fullName,
        selectedContractorCompanyName,
        userType,
        employeePhoto,
        checkedInTime,
      } = storeToRefs(useUserStore())
      const { isVisitor } = storeToRefs(useUserLogin())
      const passStatic = pass.value
      const canContinueStatic = canContinue.value
      const headingText = computed(() =>
        canContinueStatic ? 'Access Granted' : 'Access Denied',
      )
      const entryDate = dayjs(checkedInTime.value)
        .format('DD-MM-YYYY HH:mm')
        .toString()
      const siteEntryChecksStatic = siteEntryChecks.value
      const isVisitorStatic = isVisitor.value
      const getContractorName = computed(() => {
        return isVisitorStatic
          ? useUserVisitorCompaniesStore().lastSelected
          : selectedContractorCompanyName.value
      })
      const contractorName = getContractorName.value
      const siteNameStatic = siteName.value
      const siteAddressStatic = siteAddress.value
      const reportToText = computed(() =>
        canContinueStatic
          ? 'Please report to the Site Manager / Representative'
          : 'Please report immediately to the Site Manager / Representative',
      )
      const employeePhotoStatic = employeePhoto.value
      const userFullName = fullName.value
      const userTypeNameStatic = userType.value.name

      return {
        canContinueStatic,
        contractorName,
        employeePhotoStatic,
        entryDate,
        headingText,
        passStatic,
        reportToText,
        siteAddressStatic,
        siteEntryChecksStatic,
        siteNameStatic,
        userFullName,
        userTypeNameStatic,
      }
    },
  })
</script>

<style scoped>
  .information-container {
    grid-template-columns: 1fr 1.5fr;

    @apply mb-2 grid;
  }

  .pass-fail-large {
    @apply mx-auto my-4 w-1/5;
  }
</style>
