import axios from 'axios'
import {
  type EquationsType,
  type MapsType,
  type SchemaType,
  type TrolleyCollection,
} from '@verifiedit/form-generator'

export type FormSubmissionReturnType = {
  date: string
  frequencyCodes: string[]
  id: number
  serviceName: string
  serviceSiblings: {
    id: number
    name: string
    subServiceId: number
  }[]
  siteName: string
}

export const getServiceForm = async (
  customerId: number | null,
  serviceId: number | undefined,
  frequencyIds: number[],
): Promise<{
  equations: EquationsType
  maps: MapsType
  schema: SchemaType[]
  formId: number
  formName: string
}> => {
  const response = await axios.get(
    `/api/services/${serviceId}/customer-forms/${customerId}`,
    {
      params: {
        frequencyId: frequencyIds,
      },
    },
  )

  const data = response.data.data

  if (data.form) {
    return {
      equations: data.form.template.equations,
      formId: data.id,
      formName: data.name,
      maps: data.form.template.maps,
      schema: data.form.template.components,
    }
  }

  const original = data.schema

  if (original.data.equations === undefined) {
    original.data.equations = {}
    original.data.maps = {}
  }

  return {
    equations: original.data.equations,
    formId: original.data.id,
    formName: original.data.name,
    maps: original.data.maps,
    schema: original.data.schema,
  }
}

export const storeServiceRecord = async (
  deviceInfo: string,
  jobStart: string,
  jobEnd: string,
  customerId: number | null,
  siteId: number,
  serviceId: number | undefined,
  subServiceId: number | undefined,
  frequencies: number[],
  systemRemark: string,
  visitLogId: number,
  contractorCompanyId: number,
  lat: number,
  long: number,
  alt: number | null,
  accuracy: number,
  formData: string,
  formId: number | undefined,
  formName: string | undefined,
  workOrderId: number | null,
): Promise<FormSubmissionReturnType | undefined> => {
  const data = {
    accuracy: accuracy,
    alt: alt,
    contractorCompanyId: contractorCompanyId,
    customerId: customerId,
    deviceInfo: deviceInfo,
    formData: formData,
    frequencies: frequencies,
    geoLocation: lat + '*' + long,
    jobEnd: jobEnd,
    jobStart: jobStart,
    lat: lat,
    long: long,
    serviceId: serviceId,
    siteId: siteId,
    subServiceId: subServiceId,
    systemRemark: systemRemark,
    visitLogId: visitLogId,
  }

  if (workOrderId !== null) {
    data['workOrderId'] = workOrderId
  }

  if (formId !== undefined && formName !== undefined) {
    data['formId'] = formId
    data['formName'] = formName
  }

  const response = await axios.post('/api/service-records', data)

  return response?.data?.data
}

export const storeTrolleyCollection = async function (
  trolleyCollection: TrolleyCollection,
): Promise<number> {
  const response = await axios.post(
    '/api/trolley-collections',
    trolleyCollection.toJson(),
  )
  return response.status
}

export const deleteTrolleyCollection = async function (
  trolleyCollection: TrolleyCollection,
): Promise<number> {
  const response = await axios.delete('/api/trolley-collections', {
    data: trolleyCollection.toJson(),
  })
  return response.status
}
